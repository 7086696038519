import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Content from '../components/content';
import Hero from '../components/hero';
import Layout from '../components/layout';
import SEO from '../components/seo';

const PostLink = styled(Hero)`
  &:hover div + img {
    opacity: 1 !important;
    transition: none !important;
  }

  &:hover img + picture > img {
    opacity: 0 !important;
  }
`;

const IndexPage = ({ data }) => {
  const postList = data.allMdx;

  return (
    <Layout>
      <SEO title="Home" keywords={[`toyota`, `4runner`, `overland`]} />

      <Content>
        {postList.edges.map(({ node: post }, i) => (
          <PostLink
            image={post.frontmatter.hero}
            key={post.fields.slug}
            linkTo={post.fields.slug}
            title={post.frontmatter.title}
          />
        ))}
      </Content>
    </Layout>
  );
}

export default IndexPage;

export const listQuery = graphql`
  query ListQuery {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields{
            slug
          }
          frontmatter {
            title
            hero {
              childImageSharp {
                fluid(
                  duotone: {
                    opacity: 75
                    highlight: "#FED400"
                    shadow: "#FF022C"
                  },
                  maxWidth: 960,
                  traceSVG: {
                    background: "#FF741F"
                    color: "#FF022C"
                    turnPolicy: TURNPOLICY_MINORITY
                  }
                ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
