import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';

const containerStyles = css`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  position: relative;
`;

const Container = styled.header`
  ${containerStyles}
  margin-bottom: 3rem;
`;

const LinkContainer = styled(Link)`
  ${containerStyles}
  display: block;
`;

const DateLine = styled.div`
  font-size: 1rem;
  opacity: 0.8;
  padding-top: 0.5rem;
`;

const Title = styled.h1`
  color: white;
  font-size: 3rem;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);
`;

export default class Hero extends Component {
  static propTypes = {
    date: PropTypes.string,
    image: PropTypes.object.isRequired,
    linkTo: PropTypes.string,
    title: PropTypes.string.isRequired,
  };

  render() {
    const { date, image, linkTo, title, ...props } = this.props

    const content = (
      <Fragment>
        <Img className="image" fluid={image.childImageSharp.fluid} />

        <Title>
          {title}
          {date ? <DateLine>{date}</DateLine> : null}
        </Title>
      </Fragment>
    );

    return linkTo
      ? <LinkContainer to={linkTo} {...props}>{content}</LinkContainer>
      : <Container {...props}>{content}</Container>;
  }
}
